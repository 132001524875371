import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/projects.scss"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Template({ data }) {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <Layout>
      <Seo title="Steen Lauth" />

      <h1 className="projectTitle">Seneste projekter</h1>
      <div className="linkWrapper">
        {edges.map((link, i) => {
          return (
            <Link key={"projects" + i} to={link.node.frontmatter.slug}>
              <div className="link">
                <GatsbyImage
                  image={
                    link.node.frontmatter.thumbnail1.childImageSharp
                      .gatsbyImageData
                  }
                  quality={100}
                  alt={
                    link.node.frontmatter.title +
                    "Steen Lauth | Tømrer og snedker "
                  }
                  backgroundColor="#00000005"
                />
                <GatsbyImage
                  image={
                    link.node.frontmatter.thumbnail2.childImageSharp
                      .gatsbyImageData
                  }
                  quality={100}
                  alt={
                    link.node.frontmatter.title +
                    "Steen Lauth | Tømrer og snedker "
                  }
                  backgroundColor="#00000005"
                />

                <div key={"link" + i}>
                  <p>{link.node.frontmatter.title}</p>{" "}
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Projects {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            slug
            title
            thumbnail1 {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }

            thumbnail2 {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
